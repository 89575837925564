@media (min-resolution: 144dpi) and  (min-width: 992px) {
  html{
    font-size: 1.2vw;
  }
  .kv-title{
    font-size: 6rem;
  }
  .kv-subtitle{
    font-size: 1.4rem;
  }
  .section-title{
    font-size: 3.75rem;
  }
  .box-content h3{
    font-size: 1.35rem;
    // padding: 0 2.4rem;
  }
  .project-map-wrap{
    right: 12%;
    max-width: 30rem;
  }
  .project-map-panel-content{
    height: 25rem;
  }
}
@media (max-width: 991px) {
  .project-photos-wrap{
    width: 16.6666%;
  }
  .service-section{
    min-height: 110rem;
  }
}
@media (max-width: 767px) {
  html {
    font-size: 2vw;
  }
  .service-section-move2{
    display: none;
  }
  #header{
    height: 5rem;
  }
  .header-container{
    padding:0rem 2rem 0rem 2rem;
  }
  .header-bottomline{
    margin: 0 2rem;
  }
  .container, .container-s{
    padding: 0 5rem;
  }
  section{
    padding: 8rem 0;
  }
  section .section-top-line{
    left: 5rem;
    right: 5rem;
  }
  .section-title-wrap{
    margin-bottom: 2rem;
  }
  .btn-more{
    margin-bottom: 4.5rem;
  }
  .btn-more svg{
    width: 3rem;
    height: 3rem;
  }
  .btn-more .right{
    padding-left: 1rem;
  }
  .header-menu{
    overflow: hidden;
    display: block;
    position: fixed;
    right: 0;
    top: 0;
    height: 100vh;
    width: 100vw;
    transform: translateX(200%);
    background-color: @primary-color-dark;
    transition: all .5s;
    z-index: 99;
    padding: 6rem 3rem 0 3rem;
    & > li{
        padding: 0;
        margin-bottom: 0.5rem;
        height: auto;
        line-height: 4rem;
        &::after{
            content: '';
            display: block;
            width: 100%;
            transform: scaleX(0);
            transform-origin: right;
            height: 1px;
            background-color: #fff;
            transition: all .5s;
            transition-delay: .5s;
        }
        & a{
            font-size: 1.5rem;
            color: #fff !important;    
        }
    }
    & button.ant-btn,& button.ant-btn:active, & button.ant-btn:focus{
      color: #fff !important;
    }
  }
  .header-container{
    overflow: visible;
  }
  .headerTranparent{
    &.scroll .header-mobile-menu em{
        background: #0d0d0d;
    }
    & .header-mobile-menu em{
        background:#fff;
    }
  }
  .header-mobile-menu {
    width: 26px;
    height: 20px;
    cursor: pointer;
    position: absolute;
    top: 2.3rem;
    right: 0rem;
    z-index: 100;    
    em {
      display: block;
      width: 100%;
      height: 2px;
      background: #0d0d0d;
      margin-top: 6px;
      transition: all .3s;
      transition-delay: .2s;
    }
    :first-child {
      margin-top: 0;
    }
    &.open {
      height: auto;
      em {
        background-color: #fff !important;
        &:nth-child(1) {
          transform: translateY(4px) rotate(45deg);
        }
        
        &:nth-child(2) {
          transform: translateY(-4px) rotate(-45deg);
        }
      }
    }
    &.open + .header-menu{
        transform: translateX(0%);
        & .header-rightline{
            display: none;
        }
        & li::after{
            transform: scaleX(100%);           
        }        
    }
  }
  .header-dropbtn::after{
    display: none;
  }
  .header-dropmenu-bg{
    display: none;
  }
  #header .header-dropmenu a{
    color: rgba(255, 255, 255, 0.7) !important;
    font-size: 1rem;
  }
  .header-dropmenu{
    display: none;
    background-color: transparent;
    text-align: right;
    position: relative;
    left: auto;
    top: auto;
    border: 0;
    &.open{
      display: block;
      padding: 0 0 2rem 0;
    }
  }
  .page-title,.page-title-bg, .page-title-bg2{
    padding: 9rem 0 5rem 0;
    min-height: 20rem;
    & h1{
      font-size: 4.5rem;
    }
  }
  //index
  .kv-title{
    padding: 0 2rem;
    & br{
      display: block;
    }
  }
  .kv .btn-more{
    margin-bottom: 0;
  }
  .icon-wrap{
    width: 3.5rem;
    height: 3.5rem;
  }
  .box{
    display: flex;
    flex-direction: column;
  }
  .box-content{
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
  .advantage-section .ant-col + .ant-col{
    margin-top: 5rem;
  }
  .advantage-section .ant-col:nth-child(2) .box > .box-content{
    order: 0;
  }
  .advantage-section .ant-col:nth-child(2) .box > .box-photo{
    order: 1;
  }
  .news-section{
    padding: 8rem 0 0 0;
  }
  .service-section-move.fixed{
    position: relative;
    top: 0;
  }
  .service-section-move{
    position: relative;
  }
  .view-img-wrap{
    max-width: 100%;
    margin-top: 1rem;
    padding-top: 65%;
  }
  .page-service-section h2{
    margin-bottom: 1.5rem;
  }
  .solar-txt{
    margin-bottom: 2rem;
  }
  .green-img{
    margin-top: 3rem;
  }
  .wali-img{
    margin-bottom: 3rem;
  }
  .page-contact .ant-col{
    margin-bottom: 2rem;
  }
  .project-map-wrap{
    right: 1%;
    max-width: 20rem;
  }
  .project-map-panel-content{
    height: 15rem;
  }
  .project-photos-wrap{
    width: 33.3333%;
  }
  .solar-section-bg{
    display: none;
  }
  .solar-img{
    padding-left: 0;
    overflow: hidden;
    padding-top: 100%;
    height: 0;
    position: relative;
    & img{
      position: absolute;
      position: absolute;
      top: 0;
      left: 0;
      right: 0;
      width: 100%;
    }
  }
  #header.scroll button.ant-btn{
    color: #fff;
  }
  .team-box-txt-en-name{
    height: auto;
  }
}
@media (max-width: 575px) {
  html {
    font-size: 4vw;
  }
  .header-container{
    padding:0rem 1.2rem;
  }
  .header-bottomline{
    margin: 0 1.2rem;
  }
  .container, .container-s{
    padding: 0 1.6rem;
  }
  section{
    padding: 4rem 0;
  }
  section .section-top-line{
    left: 1.2rem;
    right: 1.2rem;
  }
  .section-title{
    font-size: 2.4rem;
    margin-bottom: 0rem;
  }
  .page-title{
    padding: 7.5rem 0 2rem 0;
    min-height: 10rem;
  }
  .page-title-bg, .page-title-bg2{
    padding: 7.5rem 0 5rem 0;
    min-height: 12rem;
  }
  .page-title h1, .page-title-bg h1, .page-title-bg2 h1{
    font-size: 3rem;
    letter-spacing: -0.1rem;
  }
  .page-kv .page-kv-img-wrap{
    padding-top: 80%;
  }
  .page-kv .page-kv-img{
    width: auto;
    height: 100%;
  }
  .page-news-content, .address{
    padding-top: 4rem;
  }
  .pagination{
    margin-top: 3rem;
  }
  .page-news-list .news-item{
    padding-bottom: 1rem;
    margin-bottom: 1rem;
  }
  .breadcrumb{
    margin: 1rem 0;
  }
  .btn-more{
    margin-bottom: 3rem;
  }
  .btn-more svg{
    width: 2rem;
    height: 2rem;
  }
  .btn-more i{
    width: 1rem;
    margin: -0.76875rem 0 0 -0.5125rem;
  }
  .btn-more .right{
    padding-left: 1rem;
  }
  #header{
    height: 4rem;
  }
  .header-logo img{
    width: 6.5rem;
  }
  .header-menu{
    padding: 6rem 1.2rem 0 1.2rem;
  }
  .header-mobile-menu{
    top: 1.8rem;
  }
  .kv-title{
    font-size: 2.5rem;
    letter-spacing: 0.4rem;
  }
  .kv-title-word{
    &:nth-child(5),&:nth-child(7),&:nth-child(8){
      padding-right: 1rem;
    }
  }
  .kv-subtitle{
    font-size: 1rem;
  }  
  .kv .btn-more svg{
    width: 3rem;
    height: 3rem;
  }
  .first-section-p{
    text-align: left;
  }
  .showNum-wrap{
    margin-top: 3rem;
  }
  .showNum-icon{
    font-size: 2.2rem;
  }
  .showNum-title{
    font-size: 1rem;
  }
  .showNum-title span{
    font-size: 1.5rem;
  }
  .showNum{
    margin-bottom: 1.5rem;
  }
  .news-section{
    padding: 4rem 0 0 0;
  }
  .news-item-photo{
    display: none;
  }
  .news-item-date{
    width: 25%;
  }
  .news-item-content.with-photo{
    width: 75%;
  }
  .news-page-hot .news-item{
    padding-bottom: 1rem;
  }
  .news-item{
    padding-bottom: 1.4rem;
    margin-bottom: 1.4rem;
  }
  .service-section{    
    align-items: flex-end;
    min-height: 142rem;
    &.service-section-en{
      min-height: 167rem;
    }
  }
  .box-content{
    padding: 2.4rem 1.6rem;
    min-height: 15rem;
  }
  .box-content h3{
    padding: 0;
    font-size: 1.4rem;
  }
  .box-content p{
    padding: 0;
  }  
  .service-icon{
    margin-bottom: 1rem;
  }
  .company-section-txt{
    text-align: left;
    font-size: 1rem;
  }
  .page-about-section h2{
    text-align: center;
    font-size: 2.6rem;
  }
  .page-about-section .page-section-title p{
    text-align: center;
  }
  .page-title-sub{
    font-size: 1rem;
    margin-bottom: 0.5rem;
    letter-spacing: 0.1rem;
  }
  .page-title-link{
    font-size: 0.75rem;
    text-align: center;
  }
  .team-box-txt-description{
    text-align: left;
    font-size: 1rem;
  }
  .spirits-section::after{
    height: 15%;
  }
  .spirits-photo img:nth-child(3){
    display: none;
  }
  .spirits-section p{
    margin-bottom: 2rem;
    padding: 0 1.2rem;
    letter-spacing: 0rem;
    line-height: 1.6;
  }
  .spirits-photo img{
    transform: translate3d(0px, 0px, 0px) !important;
  }
  .ant-back-top{
    right: 20px;
    bottom: 20px;
  }
  .ant-pagination-item, .ant-pagination-prev, .ant-pagination-next, .ant-pagination-jump-prev, .ant-pagination-jump-next{
    min-width: 2.35rem;
    width: 2.35rem;
    height: 2.35rem;
    line-height: 2.35rem;
  }
  .news-item-date-day{
    font-size: 2.5rem;
  }
  .news-item-date-month{
    font-size: 0.6rem;
  }
  .page-news-list .news-item .news-item-date::after{
    right: 18%;
  }
  .news-page-box{
    padding: 1.4rem;
  }
  .news-page-box-title h1{
    font-size: 1.5rem;
  }
  .news-page-box-title h1::after{
    left: -1.4rem;
    width: 5px;
  }  
  .service_img_wrap{
    width: 100%;
    height: 15rem;
    position: relative;
    overflow: hidden;
  }
  .service_img{
    position: absolute;
    left: 50%;
    top: 0;
    transform: translateX(-50%);
    margin: auto;
    width: auto;
    height: 100%;
  }
  .contact-banner{
    right: auto;
    left: 15px;
    max-width: 250px;
  }
  .project-map{
    height: 70vh;
    &::after{
      display: none;
    }
  }
  .project-map-panel-header h2{
    font-size: 1.2rem;
  }
  .project-map-panel-header, .project-map-panel-content, .project-map-panel-footer{
    padding: 0.8rem;
  }
  .project-map-panel-content{
    padding-top: 0;
    height: 10rem;
  }
  .project-map-panel-header h2::before{
    left: -0.8rem;
  }
  .project-map-wrap{
    right: 0;
    left: 0;
    bottom: 15px;
    top: auto;
    transform: translateY(0);
    max-width: 16rem;
    margin: auto;
  }
  .project-map-count span{
    font-size: 1.2rem;
  }
  .project-photos-wrap{
    width: 50%;
  }
  .ant-scrolling-effect{
    width: 100% !important;
  }
  //404
  .page-notfound-content h1{
    font-size: 10rem;
  }
  .footer h6{
    font-size: 1.2rem;
  }
  .footer .footer-link{
    font-size: 1rem;
  }
  .footer-copyright{
    font-size: 0.73rem;
  }

}