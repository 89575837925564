@import './var.less';
@import url('https://fonts.googleapis.com/css2?family=Josefin+Sans:ital,wght@0,100;0,200;0,300;0,400;0,500;0,600;0,700;1,100;1,200;1,300;1,400;1,500;1,600;1,700&family=Noto+Sans+TC:wght@100;300;400;500;700;900&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Outfit:wght@200;300;400;500;600;700;800&family=Noto+Sans+TC:wght@100;300;400;500;700;900&display=swap');
// @import url('https://fonts.googleapis.com/css2?family=Noto+Sans+TC:wght@100;300;400;500;700;900&family=Urbanist:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;1,300;1,400;1,500;1,600;1,700;1,800&display=swap');
html{
    font-size: 1vw;
    // scroll-behavior: smooth;
}
body{
    font-family: 'Josefin Sans', 'Noto Sans TC', sans-serif;
    font-weight: 300;
    line-height: 1.6;
}
h1,h2,h3,h4,h5,h6{
  font-weight: 500;
}
// scrollbar
::-webkit-scrollbar {
  width: 6px;
  height: 8px;
}
::-webkit-scrollbar-track {
  background: #ddd;
}
::-webkit-scrollbar-thumb {
  background: rgba(0,0,0,0.4);
  border-radius: 20px;  
  &:hover{
    background: rgba(0,0,0,0.5);
  }
}
body::-webkit-scrollbar-track-piece{
  background-color: transparent;
}
.body-padding{
  padding-top: 75px;
  
}

.text-center{
  text-align: center !important;
}

// data list
.datalist{
  margin-bottom: 0.2rem;
}

// page-transition
.preloader {
  top: -1%;
  height: 102%;
  position: fixed;
  pointer-events: none;
  z-index: 9999;
}
.preloader, .preloader-line {
  left: 0;
  width: 100%;
  overflow: hidden;
}
.preloader.active{
  z-index: 999;
}
.preloader-line {
  position: absolute;
  top: 0;
  height: 33.5%;
}
.preloader-line:nth-child(2) {
  top: 33.3%;
}
.preloader-line:nth-child(3) {
  top: 66.6%;
}
.preloader-line div {
  width: 100%;
  height: 100%;
  background: @primary-color-dark;
  transition: all 2s;
  transform: translate(-100%);
}
.preloader.active .preloader-line > div{
  animation: page 2s ease-in-out;
}
.preloader.active .preloader-line:nth-child(2) > div {
  animation-delay: .1s;
}
.preloader.active .preloader-line:nth-child(3) > div {
  animation-delay: .2s;
}
@keyframes page{
  25% {
    transform: translate(0%);
  }
  50% {
    transform: translate(0%);
  }
  100%{
    transform: translate(100%);
  }
}
.page-scroll{
  min-height: 100vh;
}
// .page-transition-appear, .page-transition-appear-active,.page-transition-appear-done{
//   opacity: 1;
//   width: 100%;
//   height: 100%;
// }
// .page-transition-enter{ 
//   opacity: 0;
// }
// .page-transition-enter-active {
//   opacity: 0;
//   transition: all 1s;
// }
// .page-transition-enter-done{
//   opacity: 1;
// }
// .page-transition-exit{
//   position: absolute;
//   left: 0;
//   top: 0;
//   opacity: 1;
//   z-index: 99;
//   right: 0;
// }
// .page-transition-exit-active{
//   opacity: 1;
//   transition: all 1s;
// }
// .page-transition-exit-done{
//   opacity: 0;
// }
.page-transition-enter {
  opacity: 0;
}
.page-transition-enter-active {
  opacity: 1;
  transition: opacity 500ms;
}
.page-transition-exit {
  opacity: 1;
}
.page-transition-exit-active {
  opacity: 0;
  transition: opacity 500ms;
}
// skeleton
.loading-skeleton{
  height: 16px;
  position: relative;
  background: 0 0;
  overflow: hidden;
  &::after{
    position: absolute;
    inset: 0 -150%;
    background: linear-gradient(90deg,rgba(190,190,190,.2) 25%,rgba(129,129,129,.24) 37%,rgba(190,190,190,.2) 63%);
    -webkit-animation: ant-skeleton-loading 1.4s ease infinite;
    animation: ant-skeleton-loading 1.4s ease infinite;
    content: "";
  }
}

// btn
.ant-btn{
  height: auto;
  font-size: 1rem;  
}
.ant-btn-secondary{
  background: @secondary-color;
  color: @text-color;
  border: 0;
}
.ant-btn-secondary:hover,.ant-btn-secondary:focus{
  background: @primary-color-dark;
  color: #fff;
}
//anchor
.ant-anchor{
  font-size: 0.9375rem;
}
.ant-anchor-ink{
  display: none;
}
.ant-anchor-link{
  padding: 0;
}
// modal
.ant-modal-title{
  font-size: 1.2rem;
  line-height: 1.2;
}
.ant-modal-close{
  top: 0.4rem;
}
.ant-modal-close-x{
  font-size: 1rem;
}
.ant-modal-header{
  padding: 20px 24px;
}
.ant-modal-footer .ant-btn{
  padding: 6px 15px;
}
// ant row
.ant-row-stretch{
  align-items: stretch;
}
.modal-list .ant-btn > span{
  // color: @text-color-light;
  font-size: 0.9375rem;
}
// input
.ant-input{
  font-size: 1rem;
  background-color: #F4F7F8;
  border: 1px solid rgba(0,0,0,0.01);
  &:hover{
    border: 1px solid rgba(0,0,0,0.2);
  }
  &:focus{
    border: 1px solid rgba(0,0,0,0.2);
  }
}
.ant-form-item-label > label{
  font-size: 1rem;
}
.ant-input::placeholder{
  font-size: 1rem;
}
.ant-btn-black{
  background-color: @text-color;
  border: 0;
  color: #fff;
  &:hover, &:focus{
    color: #fff;
    background-color: @primary-color-dark
  }
}
.ant-btn-primary-dark{
  background-color: @primary-color-dark;
  border: 0;
  color: #fff;
  &:hover, &:focus{
    color: #fff;
    background-color: @primary-color
  }
}
.ant-collapse,.ant-tabs-tab{
  font-size: 0.93rem;
}
//container
.container{
  width: 100%;
  padding: 0 @container-left-right-padding;
  position: relative;
}
.container-s{
  max-width: 1700px;
  padding: 0 9.5rem;
  width: 100%;
  margin: auto;
}
.section-title-wrap{
  overflow: hidden;
  margin-bottom: 4rem;
  & span{
    color: @text-color-light;
    font-size: 1rem;
    letter-spacing: .2rem;
  }
}
.section-title{
  font-weight: 600;
  line-height: 1.2;
  font-size: 3rem;
  margin-bottom: 0;  
}
section{
  padding: @section-top-bottom-padding 0;
  position: relative;
  & .section-top-line{
    position: absolute;
    top: 0;
    left: @container-left-right-padding;
    right: @container-left-right-padding;
    height: 1px;
    background-color: @text-color;
  }
}
// contact banner
.contact-banner{
  opacity: 0;
  transition: all .2s;
  position: fixed;
  right: 130px;
  bottom: 0px;
  max-width: 300px;
  width: 100%;
  z-index: 99;
  background-color: #fff;
  box-shadow: 0px 0px 8px rgba(0,0,0,0.15);
  & p{
    font-size: 0.93rem;
    color: @text-color-light;
  }
  &.show .contact-banner-body{
    padding: 1.2rem 0.8rem; 
    height: 10rem;
  }
  &.show .contact-banner-header-closed{
    display: block;
  }
  &.on{
    opacity: 1;
  }
}
.contact-banner-header{
  background-color: @text-color;
  cursor: pointer;
  color: #fff;
  display: flex;
  padding: 0.6rem 0.8rem;
  justify-content: space-between;
}
.contact-banner-header-closed{
  cursor: pointer;
  height: 100%;
  z-index: 2;
  font-size: 1rem;
  display: none;
}
.contact-banner-header-title{
  display: flex;
  align-items: center;
  & .anticon{
    margin-right: 0.5rem;
    font-size: 1.2rem;
  }
  & h3{
    color: #fff;
    margin: 0;
    font-size: 1rem;
    font-weight: 400;
    padding-top: 0.1rem;
  }
}
.contact-banner-body{
  padding: 0;  
  overflow: hidden;  
  height: 0;
  transition: all .3s;
  text-align: center;
  background-color: #fff;
}
.contact-banner-btn{
  background-color: @primary-color;
  color: #fff;
  padding: 13px 20px 10px 20px;
  font-size: 1rem;
  font-weight: 500;
  display: inline-block;
  border-radius: 3px;
  &:hover, &:focus{
    color: #fff;
  }
}
//box
.box{
  position: relative;
  width: 100%;
  height: 100%;
  box-shadow: 30px 30px 90px rgba(0, 0, 0, 0.12);
}
.box-flex-space-between{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.box-content{
  padding: 3.2rem 2rem 3.2rem 2rem;
  min-height: 19rem;
  text-align: center;
  & h3{
    position: relative;
    font-size: 1.66rem;
    margin-bottom: 1.6rem;
    padding: 0;
    line-height: 1.4;
    &::after{
      content: '';
      display: block;
      margin: 0.5rem auto;
      width: 2rem;
      height: 4px;
      background-color: @secondary-color;
    }
  }
  & p{
    font-size: 1.04rem;
    line-height: 1.6;
    margin-bottom: 0;
    padding: 0;
    color: @text-color-light;
    text-align: center;
  }
}
.box-photo{
  width: 100%;
  height: 0;
  border: 0.52rem solid transparent;
  position: relative;
  overflow: hidden;
  padding-top: 67.5%;
  & img{
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    margin: auto;
    width: 100%;

  }
}
// page-title
.page-title{
  display: flex;  
  justify-content: flex-end;
  align-items: center;
  flex-direction: column;
  padding-bottom: 2.5rem;
  padding-top:9rem;
  min-height: 25rem;
  & h1{
    color: @text-color;
    font-size: 4rem;
    font-weight: 400;
    // letter-spacing: -0.3rem;
    margin: 0;
    line-height: 0.9;
    text-align: center;
  }
}
.page-title-bg{
  min-height: 26rem;
  padding-top:13rem;
  text-align: center;
  position: relative;
  background-size: cover;
  background-position: 0% 100%;
  // color: #fff;
  padding-bottom: 4rem;
  & .breadcrumb{
    position: absolute;
    bottom: 0;
    width: 100%;
    // color: #fff;
    z-index: 89;
    & span{
      // color: #fff;
    }
    & a{
      // color: #fff;
    }
  }
  & h1{
    font-size: 4rem;
    font-weight: 400;
    // letter-spacing: -0.3rem;
    margin: 0;
    line-height: 0.9;
    text-align: center;
    // color: #fff;
    position: relative;
    z-index: 8;
  }
  & p{
    // color: #fff;
    position: relative;
    z-index: 8;
  }
  &::after{
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    // background-color: rgba(0, 0, 0, 0.2);
  }
}
.page-title-bg2{
  min-height: 26rem;
  padding-top:13rem;
  text-align: center;
  position: relative;
  background-size: cover;
  background-position: 50% 50%;
  color: #fff;
  padding-bottom: 4rem;
  & .breadcrumb{
    position: absolute;
    bottom: 0;
    width: 100%;
    color: #fff;
    z-index: 89;
    & span{
      color: #fff;
    }
    & a{
      color: #fff;
    }
  }
  & h1{
    font-size: 4rem;
    font-weight: 400;
    // letter-spacing: -0.3rem;
    margin: 0;
    line-height: 0.9;
    text-align: center;
    color: #fff;
    position: relative;
    z-index: 8;
  }
  & p{
    color: #fff;
    position: relative;
    z-index: 8;
  }
  &::after{
    content: '';
    position: absolute;
    left: 0;
    bottom: 0;
    right: 0;
    top: 0;
    background-color: rgba(0, 0, 0, 0.2);
  }
}
.page-title-sub{
  color: @text-color;
  font-weight: 300;
  font-size: 1.2rem;
  margin-bottom: 0;
  letter-spacing: 0.4rem;
  margin-bottom: 0.75rem;
  color: @text-color;
}
.page-title-link{
  display: block;
  margin: 1rem 0 0 0;
  padding: 0;
  list-style: none;
  max-width: 60rem;
  text-align: center;
  & li{
    display: inline-block;
    padding: 0 0.7rem;
  }
  & li a{
    font-size: 1rem;
    color: @text-color-light;
  }
}
.page-kv{ 
  position: relative;
  
  & .page-kv-img-wrap{
    position: relative;
    overflow: hidden;
    height: 0;
    padding-top: 40%;
  }
  & .page-kv-img{
    position: absolute;
    width: 100%;
    left: 0;
    right: 0;
    bottom: 0;
  }
  & .btn-more{
    position: absolute;
    left: 0;
    right: 0;
    top: -2rem;
    margin: auto;
    display: flex;
    flex-direction: column;
    & i{
      margin: -0.56875rem 0 0 -0.8125rem;
    }
    & .arrow-right{
      height: 0.73rem;
    }
    & .btn-more-wrap{
      background-color: @secondary-color;
      border-radius: 100%;
      width: 3.125rem;
      height: 3.125rem;
      & svg{
        width: 100%;
        height: 100%;
      }      
    }
  }
}
// breadcrumb
.breadcrumb{
  margin: 1.2rem 0;
  font-weight: 400;
  & .ant-breadcrumb{
    font-size: 0.72rem;
  }
}

// toTop
.ant-back-top{
  right: 50px;
}

//btn
.btn-more{
  display: flex;
  justify-content: flext-start;
  align-items: center;
  font-weight: 400;
  & .btn-more-wrap{
    position: relative;    
  }
  & .right{
    padding-left: 1.5rem;
  }
  & i {
    position: absolute;
    left: 50%;
    top: 50%;
    width: 1.625rem;
    height: .9375rem;
    margin: -0.76875rem 0 0 -0.8125rem;
    overflow: hidden;
    transform: rotate(0deg);
    &.arrow-right{
      transform: rotate(90deg);
    }
    &::before,::after{
      content: "";
      display: block;
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: 50% 50% no-repeat;
      background-size: contain;
      transition: transform .3s .2s;
      transform: translateX(0);
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 28 17'%3E%3Cpath fill='none' stroke='%230d0d0d' stroke-width='1.5' stroke-linecap='square' d='M1.5 8.5h24m-6-7l7 7m-7 7L26 9'/%3E%3C/svg%3E");
    }
  }
  &.secondary{
    & svg{
      stroke: @secondary-color;
    }
    & i::before,i::after{
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 28 17'%3E%3Cpath fill='none' stroke='%23FABF35' stroke-width='1.5' stroke-linecap='square' d='M1.5 8.5h24m-6-7l7 7m-7 7L26 9'/%3E%3C/svg%3E");
    }
  }
  &.white{
    & svg{
      stroke: #fff;
    }
    & i::before,i::after{
      background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 28 17'%3E%3Cpath fill='none' stroke='%23fff' stroke-width='1.5' stroke-linecap='square' d='M1.5 8.5h24m-6-7l7 7m-7 7L26 9'/%3E%3C/svg%3E");
    }
    & p{
      color: #fff;
    }
  }
  & svg{
    stroke: @text-color;
    width: 4.125rem;
    height: 4.125rem;   
    transform: rotate(-180deg);
    transition: transform 1.2s,background-color .3s;
    border-radius: 100%;
    overflow: hidden;
    & circle{
      stroke-dasharray: 267;
      stroke-dashoffset: 267px;
      transition: stroke-dashoffset 1.2s,transform 1.2s;
      transform-origin: 50% 50%;
      &:last-child{
        transform: rotate(-45deg);
      }
    }
  }
  & p{
    margin: 0;
    font-size: 1rem;
  }
  & i{
    display: block;
    position: absolute;
  }
  &.isview{
    transform: rotate(0deg);
    & svg{
      transform: rotate(0deg);
    }
    & svg circle:first-child{
      stroke-dashoffset: 22px;
    }
    
  }
}

// header
#header{
  z-index: 99;
  background-color: #fff;
  transition: all .3s;
  position: fixed;
  width: 100%;
  border-bottom: 1px solid transparent;
  & .header-dropmenu a{
    color: @text-color-light !important;
  }
  & .ant-row{
    height: 100%;
  }
  & .logo-wrap{
    display: inline-block;
    transition: all .3s;
    overflow: hidden;
    width: 100%;
  }
  & .logo-w-wrap{
    display: inline-block; 
    transition: all .3s;
    overflow: hidden;
    width: 0;
  }
  &.headerTranparent{
    color: #fff;
    background-color: transparent;
    position: fixed;
    & li a{
      color: #fff;
      font-weight: 400;
    }
    & .logo-wrap{
      width: 0;
    }
    & .logo-w-wrap{
      width: 100%;
    }
    & button.ant-btn{
      color: #fff;
      &:hover{
        color: #fff;
      }
    }
  }
  &.open{
    transition: all .1s;
    border-bottom: 1px solid #ddd;
    background-color: #fff;
    & li a{
      color: @text-color;
      transition-delay: .1s;
    }
    & .header-rightline{
      transform: scaleY(0);      
    }
    & .header-bottomline{
      transform: scaleX(0);
    }
    & .logo-wrap{
      width: 100%;
      transition-delay: .3s;
    }
    & .logo-w-wrap{
      width: 0;
    }
    & button.ant-btn{
      color: @text-color;
      transition-delay: .1s;
    }
  }
  &.scroll {
    position: fixed;
    top: 0;
    & li a{
      color: @text-color;
      transition-delay: .3s
    }
    & .header-bg {
      height: 100%;
    }
    & .header-rightline{
      transform: scaleY(0);      
    }
    & .header-bottomline{
      transform: scaleX(0);
    }
    & .logo-wrap{
      width: 100%;
      transition-delay: .5s;
    }
    & .logo-w-wrap{
      width: 0;
    }
    & button.ant-btn{
      color: @text-color;
      transition-delay: .3s;
      &:hover,&:focus,&:active{
        color: @text-color;
      }
    }
  }  
}

.header-container{
    position: relative;
    padding: 0.5rem 3rem 0rem 3rem;
    height: 100%;
}

.header-menu{
    height: 100%;
    text-align: right;
    list-style: none;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    margin: 0;
    padding: 0;
    & .ant-dropdown-trigger span{
      display: none;
    }
    & li{
        position: relative;
        padding: 0 1.56rem;
        height: 100%;
        line-height: 5.4rem;
        & a{
          font-size: 0.9375rem;
          font-weight: 400;
          height: 100%;
          padding: 0;
          display: inline-block;
        }
    }
    & button.ant-btn{
      color: @text-color;
      &:hover{
        color: @text-color;
      }
    }
}

.header {
  &-logo {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    height: 100%;    
    & img {      
      display: block;
      width: 8.8rem;
    }
    & a {
      display: flex;
    }
  }
  &-menu {
      >.menu-item {
      line-height: 62px;
      display: inline-block;
      padding: 0 20px;
      cursor: pointer;
      background: transparent;
      color: #0d0d0d;
      height: 64px;
      border-bottom-color: transparent;
      position: relative;
      transition: color .3s @ease-in-out, border .3s @ease-in-out;
      &.active,
      &:hover {
        color: @primary-color;
        border-bottom: 2px solid @primary-color;
      }
    }
  }
}
.header-bottomline{
  height: 1px;
  background-color: #fff;
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  margin: 0 3rem;
  transition: all .3s;
  transform-origin: bottom;
}
.header-rightline{
  background-color: #fff;
  position: absolute;
  width: 1px;
  height: 80%;
  left: 0;
  bottom: 1px;
  transition: all .3s;
  transform-origin: bottom;
}
.header-bg{
  background-color: #fff;
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 0;
  transition: height .3s;
  transition-delay: .3s;
  // box-shadow: 0 10px 15px rgba(0,0,0,0.05);
}
//header-dropmenu
.header-dropbtn{
  position: relative;
  &::after{
    width: 100%;
    height: 1px;
    bottom: 0;
    left: 0;
    right: 0;
    position: absolute;
    transform: scaleX(0);
    transition: all .3s;
    transition-delay: 0.1s;
    content: '';
  }
  // &.open::after{
  //   transform: scaleX(1);
  //   background-color: @secondary-color;
  // }
 
}
.header-dropmenu{
  z-index:99;
  opacity: 0;
  height: auto;
  list-style: none;
  text-align: center; 
  position: absolute;
  width: 200%;
  left: 50%;
  top: 100%;
  border-left: 1px solid #ddd;
  border-right: 1px solid #ddd;
  border-bottom: 1px solid #ddd;
  -webkit-transform: translateX(-50%) scaleY(0);
  -ms-transform: translateX(-50%) scaleY(0);
  transform: translateX(-50%) scaleY(0);
  transform-origin: top;
  padding: 0;
  line-height: 1.6;
  transition: .2s;  
  overflow: hidden;
  
  &.open{
    -webkit-transform: translateX(-50%) scaleY(1);
    -ms-transform: translateX(-50%) scaleY(1);
    transform: translateX(-50%) scaleY(1);
    opacity: 1;
    padding: 0;
    & .header-dropmenu-bg{
      transform: scaleY(1);
    }
  }
  & li{
    padding: 0.8rem 1rem;
    line-height: 1.2;    
  }
  & li + li{
    border-top: 1px solid rgba(0, 0, 0, 0.05);
    // margin-top: 1rem;
  }
}
.header-dropmenu-bg{
  background-color: #fff;
  transform-origin: top;
  transform: scaleY(0);
  height: 100%;
  width: 100%;
  position: absolute;
  z-index: -1;
  transition: all .2s;
  top:0;
  left: 0;
  right: 0;
}
.header-menuline{
  position: absolute;
  bottom: -1px;
  left: 50%;
  width: 0;
  height: 1px;
  transition: all 0.5s ease-in-out;
  background-color: @secondary-color;
}
.header-dropbg{
  width: 100%;
  height: 0;
  z-index: 9;
  opacity: 0;
  background-color: #fff;
  position: fixed;
  top: 4.9rem;
  left: 0;
  right: 0;
  box-shadow: 0px 12px 12px -17px rgb(0 0 0 / 25%);
  transition: all .2s;
  &.open{
    height: 190px;
    opacity: 1;
  }
}

// footer
.footer{  
  padding: 5rem 0 1.5rem 0;
  background-color: @primary-color-dark;
  line-height: 1.2;
  color: rgba(255, 255, 255, .6);
  & .footer-link{
    font-weight: 300;
    margin-bottom: 0.5rem;
    font-size: 0.9375rem;
    display: block;
    padding-left: 1rem;
    position: relative;
    &:hover .footer-link-img{
      opacity: 1;
      transform: rotate(180deg) translateX(-0.3rem);
    }
  }
  .footer-link-img{
    width: 0.35rem;
    height: 0.676rem;
    transform: rotate(180deg);
    transition: all 0.1s ease-out;
    position: absolute;
    opacity: .6;
    top: 0.4rem;
    left: 0;
    & img{
      width: 100%;
    }
  }
  & a{
    color: rgba(255, 255, 255, .6);
  }
  & a:hover, a:focus{
    color: rgba(255, 255, 255,1);
  }
  & h6{
    font-size: 0.9375rem;
    color: rgba(255, 255, 255, .6);
    margin-bottom: 1rem;
  }
  & .ant-row{
    margin-bottom: 3rem;
  }
  & .ant-row + .ant-row{
    margin-bottom: 0;
  }
  & .ant-divider{
    border-top: 1px solid rgba(255, 255, 255, .3);
    margin: 0.6rem 0;
  }
}
.footer-logo{
  display: block;
  margin-bottom: 4.5rem;
  width: 11rem;
  & img {
    width: 100%;
    max-width: 313px;
  }
}
.footer-copyright{
  color: rgba(255, 255, 255, .6);
  text-align: left;
  font-size: 0.73rem;
  padding: 1.4rem 0 0 0;
  font-size: 0.93rem;
}

// kv
.kv{
  contain: size;
  content-visibility: auto;
  position: relative;
  width: 100%;
  padding: 0;
  overflow: hidden;
  height: 100vh;
  &::after{
    content:"";
    display: block;
    position: absolute;
    left: 0;
    right: 0;
    bottom: 0;
    top: 0;
    background-color: rgba(0,0,0,0.1);
    z-index: -1;
  }
  & .kv-img{
    position: absolute;
    left: 0;
    top:0;
    bottom: auto;
    max-width: 100%;
    width: 100%;
    min-height: 100%;
    object-fit: cover;
    aspect-ratio: auto 1900 / 1144;
    // height: calc(100vh + 160px);
    z-index: -1;
  }
  & .btn-more{
    position: absolute;
    bottom: 2%;
    left: 50%;
    transform: translateX(-50%); 
    text-align: center; 
    color: #fff;
    flex-direction: column;
  }
}
.kv-title-wrap{
  overflow: hidden;
	top: 53%;
	margin: 0 auto;
	transform: translateY(-50%);
  position: relative;
  
}
.kv-title{  
  color: @secondary-color;
  font-weight: 600;
  font-style: italic;
  font-size: 3.6rem;
  text-align: center;
  margin: 1rem 0 0 0;
  line-height: 1.4;
  letter-spacing: 0.2rem;
  & br{
    display: none;
  }
}
.kv-subtitle{
  font-size: 1.2rem;
  text-align: center;
  color: #fff;
  font-weight: 300;
  letter-spacing: 1rem;
  margin-top: 1.5rem;
}
.kv-title-word{
  display: inline-block;
  &:nth-child(5),&:nth-child(7),&:nth-child(8){
    padding-right: 1.6rem;
  }
}

// about-section
.about-section{
  overflow: hidden;
}
.about-bg{
  position: absolute;
  left: 0;
  top:0;
  bottom: auto;
  max-width: 100%;
  width: 100%;
  min-height: 100%;
  object-fit: cover;
  aspect-ratio: auto 1900 / 1144;
  height: calc(50rem + 160px);
  z-index: -1;
}
.first-section-p{
  font-size: 1rem;
  text-align: justify;
}
.showNum{
  margin-bottom: 2rem;
  text-align: center;
}
.showNum-wrap{
  margin-top: 6.25rem;
}
.showNum-icon{
  width: 3.3rem;
  margin: 0 auto 0.5rem auto;
  line-height: 1;
  color: @secondary-color;
}
.showNum-title{
  font-weight: 500;
  font-size: 1.25rem;
  line-height: 1.1;
  & span{
    font-size: 2rem;
  }
}
.showNum-p{
  color: @text-color-light;
  font-size: 0.93rem;
}
// news-section
.news-section{
  padding: 9rem 0 0 0;

}
.news-item{
  display: flex;
  border-bottom: 1px solid #BABDBE;
  padding-bottom: 2.1rem;
  margin-bottom: 2.1rem; 
  align-items: center;  
}
.news-item-date{
  width: 20%;
  position: relative;
  text-align: left;
  color: #A9ACAD;
  padding-top: 0.5rem;
  &::after{
    content: '';
    position: absolute;
    width: 1px;
    height: 100%;
    background-color: #ddd;
    right: 25%;
    top: 0;
  }
}
.news-item-date-wrap{
  text-align: center;
  width: 50%;
}
.news-item-date-day{
  font-size: 3.33rem;
  font-weight: 400;
  line-height: 0.8;
}
.news-item-date-month{
  font-size: 1.1rem;
  font-weight: 400;
  line-height: 1;
}
.news-item-content{
  width: 80%;
  &.with-photo{
    padding-right: 2rem;
    width: 60%;
    & h3{
      display: -webkit-box;
      max-height: 2.4em;
      line-height: 1.3;
      -webkit-line-clamp: 2;
      margin-bottom: 0.5rem;
      -webkit-box-orient: vertical;
      white-space: normal;
    }
    & p{
      display: -webkit-box;      
      line-height: 1.6;
      -webkit-line-clamp: 2;
      margin-bottom: 0.8rem;
      -webkit-box-orient: vertical;
      white-space: normal;
      margin-bottom: 0;
    }
  }
  & h3{
    font-size: 1.25rem;
    white-space: nowrap;
    overflow:hidden;
    text-overflow: ellipsis;
    width: 100%;
    margin-bottom: 0;
    line-height: 1.4;
  }
  & p{
    font-size: 0.93rem;
    line-height: 1.4;
    white-space: nowrap;
    overflow:hidden;
    text-overflow: ellipsis;
    width: 100%;
    margin-bottom: 0;
    color: @text-color-light;
  }
}
.news-item-photo{
  width: 20%;
  height: 0;
  overflow: hidden;
  position: relative;
  padding-top: 16.8%;
  & img{
    position: absolute;
    left: -100%;
    right: -100%;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 100%;
  }
  & .ant-skeleton-element{
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    margin: auto;
    height: 100%;
    width: 100%;
    & .ant-skeleton-image{
      width: 100%;
      height: 100%;
    }
  }
}

// service-section
.service-section{
  min-height: 90rem;
  color: #fff;
  display: flex;
  align-items: center;
  padding-bottom: 8rem;
  overflow: hidden;
  & .section-title{
    color: #fff;
    font-weight: 400;
  }
  & .section-title-wrap span{
    color: #fff;
  }
  & .ant-row + .ant-row{
    margin-top: 3rem;
  }
}
.service-container{
  padding-top: 2rem;
}
.service-section-move{
  // transition: .3s;
  position: absolute;
  top: 0;
  left: 0;
}
.service-section-move.bottom{
  position: absolute;
  bottom: 0;
}
.service-section-move.fixed{
  position: fixed;
  top: 150px;
}
.service-section-move.fixed + .service-section-move2{
  display: none;
}
.service-section-move2{
  position: absolute;
  bottom: 0;
  left: 0;
}
.service-bg{
  position: absolute;
  left: 0;
  top:0;
  bottom: auto;
  max-width: 100%;
  width: 100%;
  min-height: 100%;
  object-fit: cover;
  aspect-ratio: auto 1920 / 1727;
  z-index: -1;
}
.service-item{
  color: #fff;
  font-size: 0.9375rem;
  & h5{
    font-size: 1.2rem;
    color: #fff;
  }
  & p{
    font-size: 0.93rem;
    // text-align: justify;
  }
}
.service-icon{
  margin-bottom: 2rem;
}
.icon-wrap{
  border-radius: 50%;
  background-color: @secondary-color;
  width: 3.6rem;
  height: 3.6rem;
  display: flex;
  justify-content: center;
  align-items: center;
  & img{
    width: 55%;
  }
}

// page-contact
.page-contact{
  padding: 0 0 4.5rem 0;
}
.address{
  // border-top: 3px solid @text-color;
  padding: 6rem 0 0 0;
}
.address-item{
  border-bottom: 1px solid #dedede;
  padding-bottom: 1.5rem;
  height: 100%;
  line-height: 1.4;
  font-size: 0.9375rem;
  & h4{
    font-weight: 500;
    margin-bottom: 0;
  }
  & h3{
    font-size: 0.9375rem;
    font-weight: 500;
    margin-bottom: 1.2rem;
  }
  & p{
    margin-bottom: 0;
    color: @text-color-light;
    line-height: 1.6;
  }
  & span{
    font-weight: 500;
  }

}
.page-contact-form{
  padding: 0 0 12rem 0;
  font-size: 1rem;
  & h3{
    margin-bottom: 2.08rem;
    font-size: 1.25rem;
  }
  & .ant-row + .ant-row{
    margin-top: 1.25rem;
  }
}

// about page
.page-section-title{
  margin-bottom: 3.6rem;
  & h2{
    color: @text-color;
    font-size: 2.92rem;
    line-height: 1.2;
    letter-spacing: 0.02rem;
    margin-bottom: 0;
    font-weight: normal;
  }
  & p{
    font-size: 1.2rem !important;
    font-weight: 400;
    margin-bottom: 0; 
  }
}
.page-about-section{
  color: @text-color-light;
  font-size:  1rem;  
}
.company-section-txt{
  text-align: left;
  font-size:  1rem;
  line-height: 1.8;
  color: @text-color;
}
.view-img-wrap{
  max-width: 75%;
  overflow: hidden;
  height: 0;
  padding-top: 60%;
  position: relative;
  margin-left: auto;
  & img{
    position: absolute;
    bottom: 0;
    width: 100%;
  }
}
.team-section{
  & .page-section-title{
    text-align: center;
  }
  & .ant-row + .ant-row{
    margin-top: 3rem;
  }
}
.team-box{
  text-align: center;
}
.team-box-photo{
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 132%;
  overflow: hidden;
  margin-bottom: 2.4rem;  
  & img{
    position: absolute;
    left: 0;
    top: 0;
    width: 100%;
  }
}
.team-box-txt-title{
  font-size: 0.9375rem;
  color: @text-color-light;
  margin-bottom: 0.416rem;
  line-height: 1;
}
.team-box-txt-name{
  font-size: 1.666rem;
  font-weight: 400;
  color: @text-color;
  letter-spacing: 0.1rem;
}
.team-box-txt-en-name{
  font-size: 1rem;
  font-weight: 300;
  color: @text-color-light;
  margin-bottom: 1.66rem;
  height: 24.5px;
  &.bold{
    font-weight: 400;
  }
}
.team-box-txt-description{
  font-size: 0.9375rem;
  text-align: left;
}
.spirits-section{
  position: relative;
  padding-bottom: 0;
  overflow: hidden;
  & .page-section-title{
    text-align: center;
  }
  & p{
    text-align: center;
    margin-bottom: 0;
  }
  &::after{
    content: '';
    height: 30%;
    width: 100%;
    background-image: linear-gradient(0deg,#004F68,transparent);
    position: absolute;
    bottom: 0;
    z-index: 9;
  }
}
.spirits-section p{
  font-size: 1rem;
  line-height: 2;
}
.spirits-img-wrap{
  max-width: 75%;
  overflow: hidden;
  height: 0;
  padding-top: 60%;
  position: relative;
  margin-right: auto;
  & img{
    position: absolute;
    top: 0;
    width: 100vw;    
  }
}
.spirits-photo{
  position: relative;
  height: 0;
  padding-top: 42%;
  overflow: hidden;
  & img{
    position: absolute;
    width: 100%;
    left: 0;
    bottom: 0;    
    z-index: -1;
    &:nth-child(1){
      height: 41.7vw;
    }
    &:nth-child(2){
      height: 35.3vw;
      z-index: 0;
      top: -50px;
      bottom: auto;      
    }
    &:nth-child(3){
      z-index: 9;
      height: 26.1vw;
    }
  }
}
.history-section{
  color: #fff;
  background-color: @primary-color-dark;
  border-bottom: 1px solid rgba(255, 255, 255, .3);
  & .page-section-title{
    text-align: center;
    margin-bottom: 4rem;
    color: #fff;
    & h2{
      color: #fff;
    }
  }

}
.history-box{
  width: 100%;
  margin: 0 0 0 auto;
  
  &:first-child .line > span{
    top: 0.5rem;
    &::after{
      top: -0.3rem;
    }
    &::before{
      top: -0.383rem;
      border: 0.45rem solid rgba(255, 255, 255, .2);
    }
  }
  &:last-child .history-box-item{
    min-height: 2rem;
  }
  &:last-child .line > span{
    background: linear-gradient(0deg,transparent 80%,rgba(255, 255, 255, .2) 90%);    
  }
}
.history-box-item{
  width: 100%;
  display: flex;
  min-height: 5.5rem;
  & .year{
    width:49.5%;
    font-size: 1.666rem;
    font-weight: 300;
    text-align: right;
    padding-right: 4rem;
    letter-spacing: 0.1rem;
    line-height: 1.1;
  }
  & .line{
    position: relative;
    width:5%;
    & span{
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      width: 1px;    
      background: rgba(255, 255, 255, .2);
      display: block;
      &::before{
        content: '';
        display: block;
        width: 1.14rem;
        height: 1.14rem;
        background-color: transparent;
        border-radius: 100%;
        position: absolute;
        left: -0.555rem;
        top: 0.25rem;
        // border: 0.2rem solid transparent;
        z-index: 1;
      }
      &::after{
        content: '';
        display: block;
        width: 1rem;
        height: 1rem;
        background-color: @secondary-color;
        border-radius: 100%;
        position: absolute;
        left: -0.49rem;
        top: 0.3rem;
        border: 0.3rem solid @primary-color-dark;
        z-index: 2;
      }
    }
  }
  & .description{
    width: 45.5%;
    & p{
      font-size: 0.9375rem;
      color: rgba(255, 255, 255, 1);
      margin-bottom: 1rem;
      line-height: 1.6;
    }
    
  }
}

// news page
.page-news-section{
  padding-top: 0;
}
.page-news-content{
  // border-top: 3px solid @text-color;
  padding-top: 6rem;
}
.page-news-list .news-item{
  padding-bottom: 1.4rem;
  margin-bottom: 1.4rem;  
}
.pagination{
  text-align: center;
  margin-top: 5rem;
}
.ant-pagination-prev .ant-pagination-item-link, .ant-pagination-next .ant-pagination-item-link{
  border: 0;
  &:hover{
    color: @text-color;
  }
}
.ant-pagination-item{
  border: 0;
  width: 2.65rem;
  min-width: 2.65rem;
  height: 2.65rem;
  font-size: 1rem;
  line-height: 2.65rem;
  border-radius: 50%;
  & a{
    font-weight: 400;
    &:hover, &:focus{
      font-weight: 500;
      color: @text-color;
    }
  }
}
.ant-pagination-prev, .ant-pagination-next, .ant-pagination-jump-prev, .ant-pagination-jump-next{
  width: 2.65rem;
  min-width: 2.65rem;
  height: 2.65rem;
  font-size: 1rem;
  line-height: 2.65rem;
}
.ant-pagination-item-active{
  background-color: @secondary-color;
  & a{
    color: @text-color;
    font-weight: 500;
  }  
}
.page-news-page-section{
  padding: 0;
}
.news-page-box{
  background-color: #fff;
  box-shadow:  0px 0px 40px rgba(0, 0, 0, 0.12);
  padding: 3.5rem;
  margin-bottom: 2rem;
  min-height: 80vh;
  & iframe{
    max-width: 100%;
  }
}
.news-page-next{
  padding-bottom: 9rem;
}
.news-page-hot{
  padding-bottom: 9rem;
  & .news-item{
    margin-bottom: 0;
  }
}
.news-page-next-back{
  font-size: 1.25rem;
  font-weight: 600;
}
.pre-next{
  display: flex;
  justify-content: flex-end;
  & .btn-more{
    margin-bottom: 0;
  }
}
.pre-btn{
  margin-right: 1rem;
  & i{
    transform: rotate(180deg);
  }
}
.news-page-hot-title{
  font-size: 1.666rem;
  line-height: 1;
  font-weight:600;
  border-bottom:3px solid @text-color;
  padding-bottom:1.66rem;
  margin-bottom: 2.66rem;
}
.news-page-box-title{
  & .date{
    color: @text-color-light;
    margin-bottom: 0rem;
    font-weight: 500;
    font-size: 1rem;
    line-height: 1.2;
  }
  & .publish{
    color: @text-color-light;
    font-size: 0.9375rem;
    line-height: 1.2;
  }
  & h1{
    font-weight: 500;
    font-size: 1.875rem;
    margin-bottom: 2.4rem;
    line-height: 1.4;
    position: relative;
    &::after{
      content: '';
      position: absolute;
      left: -3.5rem;
      width: 8px;
      height: 50px;
      top: 0;
      background-color: @secondary-color;
    }
  }
}
.news-page-box-content{
  color: @text-color-light;
  font-size: 1rem;
  word-break: break-word;
  & a{
    text-decoration: underline;
  }
  & img{
    max-width: 100%;
    margin-bottom: 1rem;
  }
  & figcaption{
    color: @text-color-light;
    font-size: 0.833rem;
  }
  & iframe{
    margin-bottom: 1rem;
  }
}

.page-service-section{
  color: @text-color-light;
  font-size:  1rem;
  & h2{
    color: @text-color;
    font-size: 1.6rem;
    font-weight: 600;
    line-height: 1.2;
    letter-spacing: 0.02rem;
    margin-bottom: 2rem;
  }
}
.service-hash-ul{
  list-style: none;
  margin: 0;
  padding: 0;
  display: flex;
  flex-wrap: wrap;
  & li{
    line-height: 1;
    border-radius: 2rem;
    margin-right: 1rem;
    font-size: 0.9375rem;
    color: #008CB5;
    margin-bottom: 0.5rem;
  }
}
.solar-txt + .solar-txt{
  margin-top: 3rem;
}
.solar-img{
  padding-left: 4rem;
  & img{
    max-width: 100%;
  }
}
.green-img{
  width: 100%;
  padding-top: 100%;
  height: 0;
  border-radius: 50%;
  overflow: hidden;
  position: relative;
  margin-left: auto;
  & img{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 110%;
  }
}
.green-txt + .green-txt{
  margin-top: 3.6rem;
}
.wali-img{
  width: 100%;
  padding-top: 100%;
  height: 0;
  overflow: hidden;
  position: relative;
  margin-left: auto;
  & img{
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    width: 110%;
  }
}
.lightdonate-section{
  padding-bottom: 0;
  position: relative;
  
}
& .solar-section-bg{
  position: absolute;
  bottom: -2.5rem;
  right: 0;
  z-index: -1;
  padding: 0 9.5rem;
  & img{
    max-width: 100%;
  }
}
.service_img{
  width: 100%;
}
.lightdonate-txt{
  margin-bottom: 2rem;
}
.lightdonate-margin{
  margin-bottom: 25rem;
}
.page-notfound{
  padding: 12rem 0 6rem 0;
  background-size: cover;
  background-position: center center;
  height: 100vh;
  width: 100%;
  display: flex;
  align-items: center;
  overflow: hidden;
}
.page-notfound-content{
  text-align: center;
  & h1{
    font-size: 20rem;
    color: #fff;
    font-weight: 300;
    margin-bottom:0;
    line-height: 1;
  }
  & p{
    color: #fff;
    font-size: 2rem;
  }
  & .btn-more{
    justify-content: center;
  }
}

// project
.project-map{
  width: 100%;
  height: 840px;
  background-color: #e2ecee;
  position: relative;
  & > div:first-child > div{
    background-color: #e2ecee !important;
  }
  &::after{
    content:'';
    position: absolute;
    top: 0;
    right: 0;
    display: block;
    width: 50%;
    height: 100%;
    background-image: linear-gradient(90deg,rgba(226,236,238,0) ,#e2ecee 5%);
  }
}
.project-map-wrap{
  display: block;
  position: absolute;
  right: 15%;  
  transform: translateY(-50%);
  top: 50%;
  width: 100%;
  max-width: 30rem;
  z-index: 9;
}
.project-map-panel{
  background-color: #fff;
  width: 100%;
  box-shadow: 30px 30px 100px rgba(0, 0, 0, 0.14);
}
.project-map-panel-header, .project-map-panel-content, .project-map-panel-footer{
  padding: 1.5rem;
}
.project-map-panel-header{
  display: flex;
  justify-content: space-between;
  align-items: center;
  & h2{
    position: relative;
    margin-bottom: 0;
    min-width: 50%;
    &::before{
      content:'';
      position: absolute;
      left: -1.5rem;
      top: 0;
      width: 5px;
      height: 100%;
      background-color: #FABF35;
    }    
  }
}
.project-map-area-count{
  font-size: 1rem;
}
.project-map-count{
  text-align: right;
  font-size: 1rem;
  & span{
    font-size: 2rem;
    font-weight: 400;
  }
}
.project-map-panel-content{
  height: 20.5rem;  
  padding-top: 0;
}
.project-map-panel-scroller{
  overflow-y: auto;
  height: 100%;
  width: 100%;
}
.ant-image-preview-img-wrapper,.ant-image-preview-body{
  box-shadow: none;
}
.gm-style-moc{
  display: none;
}
.project-map-mask{
  width: 100%;
  height: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 0.05);
}
.project-photos{
  background-color: #004960;
}
.project-photos .my-masonry-grid_column > div{
  margin-bottom: 0;
}
.project-photos-container{
  display: flex;
  flex-wrap: wrap;
}
.project-photos-wrap{
  width: 11.111%;
  font-size: 0;
  position: relative;
  &.big{
    width: 100%;

    & .project-photos-mask{
      background-color: rgba(0, 0, 0,0);
    }
  }
  & .ant-image-mask{
    background:none;
    & .ant-image-mask-info{
      display: none;
    }
  }
  & .ant-image{
    height: 0;
    overflow: hidden;
    padding-top: 60%;    
  }
  & .ant-image-img{
    position: absolute;
    left: 0;
    top: 0;
    height: 100%;
  }
  & .ant-image-mask-info{
    font-size: 0.93rem;
  }
  &:hover .project-photos-text{
    opacity: 1;
  }
  &:hover .project-photos-mask{
    background-color: rgba(0, 0, 0,0);
  }
}
.project-photos-mask{
  background-color: rgba(0, 0, 0, 0.4);
  position: absolute;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: block;
  z-index: 1;
  transition: .2s;
  pointer-events: none;
}
.project-photos-text{
  cursor: pointer;
  position: absolute;
  pointer-events: none;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  display: flex;
  justify-content: flex-end;
  flex-direction: column;
  z-index: 9;
  color: #fff;
  padding: 0.5rem;
  background-image: linear-gradient(0, rgba(0,0,0,0.7) 5%,transparent 50%);
  opacity: 0;
  transition: .2s;
  & h4{
    color: #fff;
    font-size: 1rem;
    margin-bottom: 0;
  }
  & p{
    color: #fff;
    font-size: 0.833rem;
    margin-bottom: 0;
  }
}
.ant-image-preview-img-wrapper,.ant-image-preview-content,.ant-image-preview-body{
  height: 100%;
}

// admin
.ant-layout-header{
  background-color: #fff;
}
.site-layout-background{
  background-color: #fff;
}
.ant-layout{
  padding: 0;
}
.news-page-box figcaption {
  text-align: center;
}